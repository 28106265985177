.p{
    &__rVieBtn{
        font-size: 12px;
        min-height: 40px;
        padding: 0.5rem 1rem;
        margin: 30px auto 3px auto;
        background-color: #3f51b5;
        border-color:  #141414;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }
        &:disabled{
                background-color: #3f51b5;
                opacity: 0.65;
        }
        &.block{
            width: 100%;
        }

    } 
    &__cartItemName{
        text-transform: none;
    }
    &__rVieInfo{
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        padding-bottom: 0.2em;
        font-style:italic;
    }
    &__rVieBold{
        font-size: 1em;
        color: rgba(0,0,0,1);
        font-weight: 700;
    }  
    &__fixed{
        position: sticky;
        top: 0;
    }  
    &__border{
        border: 4px solid rgba(0,0,0,0.1);
        border-radius: 4px;
    }
    &__option{
        display: flex;
        flex-direction: row;
        padding: 20px 0px 20px 0px;
        &:before, &:after{
            content: "";
            flex: 1 1;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            margin: auto;
        }
        &:before{
            margin-right: 10px

        }
        &:after{
            margin-left: 10px
        }
    }
    &__iconDanger{
        color: #d9534f;
    }
    &__crossthrough{
      text-decoration: line-through;
      color: rgba(0,0,0,0.4);
    }
}
