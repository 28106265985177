.p{
    &__card{
        display: block;
        width: 100%;
        height: calc(1.5em + 1.5rem);
        padding: 0.75rem 1.5rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #55595c;
        background-color: #f7f7f9;
        background-clip: padding-box;
        border: 0 solid #ced4da;
        border-radius: 0;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
        letter-spacing: 1px;   
        &:disabled{
            background-color: #d6d6d6;
        }   
    } 
    &__rVieBtn{
        font-size: 12px;
        min-height: 40px;
        padding: 0.5rem 1rem;
        margin: 30px auto 3px auto;
        background-color: #3f51b5;
        border-color:  #141414;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    }     
    &__detail{
        margin:20px auto 20px;
    }        
}