.p{
    &__container{
        text-align: left;
    } 
    &__rVieBtn{
        font-size: 12px;
        min-height: 40px;
        padding: 0.5rem 1rem;
        margin: 30px auto 3px auto;
        background-color: #3f51b5;
        border-color:  #141414;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    }    
}