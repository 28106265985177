.rvNSDiscount{
    .vButton{
        height: 45px;
    }

    @media (max-width: 767px) {  


    }

}